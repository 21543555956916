<template>
  <div class="score">
    <span style="margin-right:20px">年度</span>
    <el-date-picker v-model="queryYear"
      value-format="yyyy"
      type="year"
      @change="loadTeacherYearsExamine"
      placeholder="请选择年度">
    </el-date-picker>
    <el-table :data="examineTable"
      ref="examineTable">
      <el-table-column label="年度"
        width="80px"
        align="center"
        prop="nd"></el-table-column>
      <el-table-column label="名称"
        align="center"
        prop="name"></el-table-column>
      <el-table-column label="类型"
        align="center"
        prop="typeStr"></el-table-column>
      <el-table-column label="开始时间-结束时间"
        align="center">
        <template slot-scope="scope">
          <span>
            {{scope.row.starttime}}--{{scope.row.endtime}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="发布人"
        align="center"
        prop="opername"></el-table-column>
      <el-table-column label="状态"
        align="center">
        <template slot-scope="scope">
          <el-tag :type="tagColor(scope.row.status)">{{scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作"
        align="center"
        width="130px">
        <template slot-scope="scope">
          <el-button type="warning"
            @click="viewDetails(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "score",
  data() {
    return {
      examineTable: [],
      currentId: "",
      currentType: "",
      queryYear: "",
    };
  },
  created() {
    this.defaultQueryYear();
    this.loadTeacherYearsExamine();
  },
  methods: {
    defaultQueryYear() {
      let date = new Date();
      this.queryYear = String(date.getFullYear());
    },
    loadTeacherYearsExamine() {
      const that = this;
      let data = { nd: Number(this.queryYear) };
      this.$post("/base/queryEvaluation.do", data)
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].type === 2 || res.data[i].status === 3) {
              res.data.splice(i, 1);
              i -= 1;
            }
          }
          that.examineTable = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewDetails(row) {
      this.$router.push({
        name: "scoringOperation",
        query: { id: row.id, type: row.type, status: row.status },
      });
    },
    tagColor(status) {
      switch (status) {
        case 0:
          return "success";
        case 1:
          return "warning";
        case 2:
          return "info";
        case 3:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-box {
  padding: 0 15px;
}
</style>
